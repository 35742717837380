<template>
  <div class="market">
    <div class="quotes">
      <el-table
        :data="sellInfo"
        height="380"
      >
        <el-table-column
          :label="`${$t(250)}(USDT)`"
          width="100"
        >
          <template slot-scope="scope">
            <span :style="{color: fallColor}">
              {{ scope.row.price }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="`${$t(245)}(${futureMap[curCoinName+curMediumName] ? (futureMap[curCoinName+curMediumName].alias||$store.state.curCoinName) :$store.state.curCoinName})`"
          width="115"
        >
          <template slot-scope="scope">
            <span class="colorfff">
              {{ scope.row.volume }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="`${$t('256')}(${futureMap[curCoinName+curMediumName] ? (futureMap[curCoinName+curMediumName].alias || $store.state.curCoinName):$store.state.curCoinName})`"
          width="115"
        >
          <template slot-scope="scope">
            <span class="colorfff">
              {{ scope.row.sum }}
            </span>
            <div class="bgrec" :style="{'background-color':fallColor, width: 330*scope.row.deal+'px'}"></div>
          </template>
        </el-table-column>
        <template slot="empty">
          <div class="text-center">
            <img src="../../../assets/img/nodata.png" alt="" style="width:140px;height: auto;"><br>
            <span>{{ $t(255) }}</span>
          </div>
        </template>
      </el-table>
      <div class="curprice">
        <span class="fs18">{{ coinPriceMap[curCoinName+curMediumName].price }} USDT </span><span class="fs14 color666">≈{{  (curRate * coinPriceMap[curCoinName+curMediumName].price).toFixed(2) }} {{ userCurrency }}</span>
      </div>
      <el-table
        :show-header="false"
        :data="buyInfo"
        height="356"
      >
        <el-table-column
          width="100"
        >
          <template slot-scope="scope">
            <span :style="{color: riseColor}">
              {{ scope.row.price }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          width="115"
        >
          <template slot-scope="scope">
            <span class="colorfff">
              {{ scope.row.volume }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          width="115"
        >
          <template slot-scope="scope">
            <span class="colorfff">
              {{ scope.row.sum }}
            </span>
            <div class="bgrec" :style="{backgroundColor:riseColor, width: 330*scope.row.deal+'px'}"></div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getBooks, getDoneRecords } from '@/api'
import tradingMixin from '@/mixins/tradingMixin'
import { formatTime } from '@/utils'
import utils from '@/utils/misc'
// import {Message} from 'element-ui'
export default {
  name: 'Market',
  components: {
  },
  mixins: [tradingMixin],
  data() {
    return {
      data: [],
      timer: null,
      type: 'market',
      buyInfo: [],
      sellInfo: []
    }
  },
  computed: {
    hasToken() {
      return localStorage.token
    },
    coinMsg() {
      return this.$store.state.coinMsg
    }
  },
  watch: {
    curCoinName() {
      this.sendSubInfo()
    }
  },
  beforeMount() {
    this.sendSubInfo()
    this.bindEvents()
    utils.on('wsopen', this.sendSubInfo)
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    filterData(data, order) {
      let arr = data || []
      if (order === 'sell') {
        arr.reverse()
      }
      let sum = 0
      arr = arr.map(item => {
        item.volume = (+item.volume).toFixed(6)
        sum = !sum ? +item.volume : +sum + (+item.volume)
        item.sum = sum.toFixed(6)
        return item
      })
      if (order === 'sell') {
        arr.reverse()
      }
      return arr
    },
    sendSubInfo() {
      const symbol = utils.getLs('timeSymbol') || 'BTCUSDT'
      if (this.$ws.ws.m_state === 1) {
        this.$ws.send({
          action: 10005,
          data: {
            type: this.$route.name==='Spot' ? 8 : 5,
            symbol
          }
        })
        this.$ws.send({
          action: this.$route.name==='Spot' ? 10007 : 10010,
          data: {
            symbol: `${this.curCoinName}USDT`,
            platform: utils.getLs('agentIdentify'),
            userId: utils.getLs('userId'),
          }
        })
      } else {
        setTimeout(() => {
          this.sendSubInfo()
        }, 100)
      }
      utils.setLs('timeSymbol', `${this.curCoinName}USDT`)
    },
    bindEvents() {
      // 20007/20009-服务端推送20档行情数据
      utils.on("REC_DEPTH_INFO", data => {
        if (!data.data.buy.length || !data.data.sell.length) return
        let buy = []
        let sell = []
        let buyRate = Math.random()
        let sellRate = Math.random()
        data.data.buy.map((item, i) => {
          buy.push({
            price: item.price,
            volume: item.volume,
            deal: Math.random()
          })
        })
        data.data.sell.map((item, i) => {
          sell.push({
            price: item.price,
            volume: item.volume,
            deal: Math.random()
          })
        })
        this.buyInfo = this.filterData(buy).slice(10,20)
        this.sellInfo = this.filterData(sell, 'sell').slice(0, 10)
      });
    }
  }
}
</script>

<style lang="scss">
.market{
  height: 100%;
  .curprice{
    background: #fff;
    padding: 14px 8px;
    color: #333;
  }
  .el-table::before{
    display: none;
  }
  .el-table__row{
    position: relative;
    .bgrec{
      position: absolute;
      top: 0;
      right: 0;
      opacity: .1;
      height: 36px;
      transition: width ease-in-out .5s;
    }
  }
  .quotes{
    .el-icon-star-on{
      color: #61688a;
      font-size: 18px;
      margin-right: 2px;
      &.light{
        color: #00d3ad;
      }
    }
    .name{
      color: #b0b8db;
    }
  }
  .el-tabs{
    padding: 0px;
  }
  .el-tabs__item{
    color: #b0b8db;
  }
  .el-table__header-wrapper{
    padding-left: 4px;
  }
  .el-table th {
    padding: 0px !important;
  }
  .el-table td.text-left .cell{
    padding-left: 20px;
    height: 20px;
  }
  .el-tabs__item.is-active {
      color: #00d3ad;
  }
  .el-button.empty-login{
    font-size: 13px;
    text-decoration: underline;
    color: #909399;
  }
  .el-table__empty-text{
    font-size: 12px;
    line-height: 20px
  }
}
</style>
